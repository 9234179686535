import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import Constants from '../../Constants';
import BaseSection from '../../Components/BaseSection';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import TextSection from '../../Components/TextSection';
import {useOutlet} from 'reconnect.js';

import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';

import {gTagEvent} from '../../Hooks/useGoogleAnalytics';

import {Button, Input} from 'antd';
import {Radio, Select, Space} from 'antd';

import nft from '../../Utils/Nft';

//import {compress, decompress} from 'lzutf8';
import LZString from 'lz-string';

import CryptoJS from 'crypto-js';

import * as Scroll from 'react-scroll';

// Function to compress and encrypt a string
const compressAndEncrypt = (text, password) => {
  // Compress the text
  //const compressed = compress(text, {outputEncoding: 'Base64'});
  const compressed = LZString.compressToBase64(text);

  // Encrypt the compressed text using the password
  const encrypted = CryptoJS.AES.encrypt(compressed, password).toString();

  // Return the encrypted text
  return encrypted;
};

// Function to decrypt and decompress a string
const decryptAndDecompress = (encryptedText, password) => {
  // Decrypt the encrypted text using the password
  const bytes = CryptoJS.AES.decrypt(encryptedText, password);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  // Decompress the decrypted text
  //const decompressed = decompress(decrypted, {inputEncoding: 'Base64'});
  const decompressed = LZString.decompressFromBase64(decrypted);

  // Return the decompressed text
  return decompressed;
};

// Usage example
/*
const _password = '6a[9/M[u6ubcByRERhh.}CV.fA^eGH';
const originalText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing elit.' +
  'Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing elit.' +
  'Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing elit.';
const encryptedText = compressAndEncrypt(originalText, _password);
const decryptedText = decryptAndDecompress(encryptedText, _password);

console.log('!!!! 1', originalText, originalText.length); // "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
console.log('!!! 2', encryptedText, encryptedText.length); // "U2FsdGVkX19zvG8XlB6YIY7Vn0n05vvd8VJW6q3q/yM5Q5c5x6XoUamMDG60dH5P"
console.log('!!! 3', decryptedText); // "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
*/

function Landing(props) {
  useOutlet('update-counter');
  const [test, setTest] = React.useState({});
  const [dimension] = useOutlet('dimension');

  const isMobile = dimension.rwd === 'mobile';

  return (
    <Wrapper
      style={{backgroundImage: `url("/images/bg${isMobile ? '3' : ''}.png")`}}>
      <div style={{width: '100%', maxWidth: 1024}}>
        <Navbar />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>
        <InfoSection doTest={setTest} />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>
        <div
          style={{
            width: '100%',
            maxWidth: 1024,
            backgroundColor: 'rgba(255,255,255,0.3)',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}>
          <div style={{padding: 10, flex: 1, minWidth: 300}}>
            <EncodeSection />
          </div>
          <div style={{padding: 10, flex: 1, minWidth: 300}}>
            <DecodeSection test={test} />
          </div>
        </div>
      </div>

      <div style={{width: '100%', maxWidth: 1024}}>
        <Footer />
      </div>
    </Wrapper>
  );
}

const InfoSection = ({style = {}, doTest = () => 0}) => {
  const [more, setMore] = React.useState(false);
  return (
    <div
      style={{
        width: '100%',
        maxWidth: 1024,
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        padding: '10px 20px',

        ...style,
      }}>
      <div
        style={{
          padding: '25px 20px',
          backgroundColor: '#643843',
          borderRadius: 10,

          alignSelf: 'center',
        }}>
        <h1 style={{margin: 0, color: '#EEEEEE'}}>
          Encrypt & Decrypt Text Online (AES256)
        </h1>
      </div>
      <br />

      <h2 style={{color: '#5F264A', alignSelf: 'center'}}>
        The simplest way to use AES256 encrypt/decrypt your Data !
      </h2>

      <a
        href="/txt-storage_v1/"
        target="_blank"
        style={{
          color: '#FEA1A1',
          backgroundColor: '#F0EDD4',
          borderRadius: 20,
          width: '60%',
          minWidth: 350,
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'row',
          wordWrap: 'break-word',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5px 15px',
          fontWeight: 'bold',
          fontSize: 16,
          marginTop: 30,
          marginBottom: 30,
        }}>
        Store the AES256 data on Optimism chain for permanent use ➜
      </a>
    </div>
  );
};

function partialAddress(address = '', h = 8, t = 8) {
  const head = address.substring(0, h);
  const end = address.substring(address.length - t, address.length);
  return head + '...' + end;
}

const EncodeSection = () => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [text, setText] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [uploadIdx, setUploadIdx] = React.useState(null);

  const [gasMul, setGasMul] = React.useState('Low');

  function isDataValid(s) {
    return s !== null && s !== undefined && s !== '';
  }

  const encryptedResult =
    isDataValid(text) && isDataValid(password)
      ? compressAndEncrypt(text, password)
      : null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#569DAA',
        opacity: 0.95,
        padding: 15,
        borderRadius: 10,
      }}>
      <h1
        style={{
          backgroundColor: '#263A29',
          color: '#F2E3DB',
          padding: '5px 15px',
          borderRadius: 5,
        }}>
        Encrypt Text With AES256
      </h1>

      <h3 style={{marginTop: 30, alignSelf: 'flex-start'}}>
        Data to be encrypted{text && `(data length：${text.length})`}
      </h3>
      <Input.TextArea
        value={text}
        rows={4}
        style={{marginTop: -10, width: '100%'}}
        placeholder="Please input your data"
        onChange={(e) => setText(e.target.value)}
      />
      <h3 style={{marginTop: 20, alignSelf: 'flex-start'}}>Password</h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <Input.Password
          placeholder="Please Input password"
          style={{width: '50%'}}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
        />

        <Button
          style={{width: '30%'}}
          onClick={() => {
            gTagEvent('Click', {name: 'Gen Pwd (push section)'});
            setPassword(generatePassword(16));
          }}>
          Gen Pwd
        </Button>
      </div>

      {encryptedResult && (
        <div
          style={{
            width: '100%',
            backgroundColor: '#EEEEEE',
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            padding: 20,
            cursor: 'pointer',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: 300,
            }}>
            <h4 style={{marginBottom: 0}}>Encrypted Result</h4>
            &nbsp;&nbsp;
            <div
              style={{
                padding: '5px 10px',
                backgroundColor: 'lightgrey',
                cursor: 'pointer',
                borderRadius: 10,
              }}
              onClick={async () => {
                if (password) {
                  await window.navigator.clipboard.writeText(encryptedResult);
                  window.alert('Encrypted Result copyied !');
                }
              }}>
              Copy
            </div>
          </div>
          <Input.TextArea
            value={encryptedResult}
            row={10}
            style={{
              marginTop: 20,
              height: Math.ceil(encryptedResult.length / 30) * 15,
            }}></Input.TextArea>
        </div>
      )}
    </div>
  );
};

const DecodeSection = ({test = {}}) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [dataID, setDataID] = React.useState(null);
  const [inD, setInD] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  React.useEffect(() => {
    async function f() {
      if (test.id != null && test.password != null) {
        setDataID(test.id);
        setPassword(test.password);
        await delay(200);
        try {
          const ret = await nft.getData(dataID);

          delay(500);
          setText(decryptAndDecompress(ret, password));
        } catch (err) {
          console.log('fail ...', err);
          setText(null);
          /*
          window.alert(
            'Error ... \n1. Please check data ID and password \n2. We need MetaMask for connecting to blockchain',
          );
          */
        }
      }
    }
    f();
  }, []);

  const text = inD && password ? decryptAndDecompress(inD, password) : null;

  function isDataValid(s) {
    return s !== null && s !== undefined && s !== '';
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#393646',
        opacity: 0.9,
        padding: 15,
        borderRadius: 10,
        color: 'white',
      }}>
      <Scroll.Element name="_DecodeSection">
        <h1
          style={{
            backgroundColor: '#4F4557',
            color: '#F4EEE0',
            padding: '5px 15px',
            borderRadius: 5,
          }}>
          Decrypt Text With AES256
        </h1>
      </Scroll.Element>

      <h3 style={{marginTop: 30, alignSelf: 'flex-start', color: 'white'}}>
        Data to be decrypted{text && `(data length：${inD.length})`}
      </h3>
      <Input.TextArea
        value={inD}
        rows={4}
        style={{marginTop: -10, width: '100%'}}
        placeholder="Please input your data"
        onChange={(e) => setInD(e.target.value)}
      />

      <>
        <h3 style={{marginTop: 20, alignSelf: 'flex-start', color: 'white'}}>
          Password
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}>
          <Input.Password
            placeholder="Please Input password"
            style={{width: '50%'}}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
        </div>

        {inD && password && text && (
          <div
            style={{
              width: '100%',
              backgroundColor: '#EEEEEE',
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              padding: 20,
              cursor: 'pointer',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
              }}>
              <h4 style={{marginBottom: 0}}>Decrypted Result</h4>
              &nbsp;&nbsp;
              <div
                style={{
                  padding: '5px 10px',
                  backgroundColor: 'lightgrey',
                  cursor: 'pointer',
                  borderRadius: 10,
                }}
                onClick={async () => {
                  if (password) {
                    await window.navigator.clipboard.writeText(text);
                    window.alert('Decrypted Result copyied !');
                  }
                }}>
                Copy
              </div>
            </div>
            <Input.TextArea
              value={text}
              row={10}
              style={{
                marginTop: 20,
                height: Math.ceil(text.length / 30) * 15,
              }}></Input.TextArea>
          </div>
        )}
      </>
    </div>
  );
};

const Wrapper = styled.div`
  background-image: url('/images/bg.png');
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

function generatePassword(length, rules) {
  if (!length || length == undefined) {
    length = 8;
  }

  if (!rules || rules == undefined) {
    rules = [
      {chars: 'abcdefghijklmnopqrstuvwxyz', min: 3}, // As least 3 lowercase letters
      {chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', min: 2}, // At least 2 uppercase letters
      {chars: '0123456789', min: 2}, // At least 2 digits
      {chars: '!@#$&*?|%+-_./:;=()[]{}', min: 1}, // At least 1 special char
    ];
  }

  var allChars = '',
    allMin = 0;
  rules.forEach(function (rule) {
    allChars += rule.chars;
    allMin += rule.min;
  });
  if (length < allMin) {
    length = allMin;
  }
  rules.push({chars: allChars, min: length - allMin});

  var pswd = '';
  rules.forEach(function (rule) {
    if (rule.min > 0) {
      pswd += shuffleString(rule.chars, rule.min);
    }
  });

  return shuffleString(pswd);
}
function shuffleString(str, maxlength) {
  var shuffledString = str
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  if (maxlength > 0) {
    shuffledString = shuffledString.substr(0, maxlength);
  }
  return shuffledString;
}

var pswd = generatePassword(15, [
  {chars: 'abcdefghijklmnopqrstuvwxyz', min: 4}, // As least 4 lowercase letters
  {chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', min: 1}, // At least 1 uppercase letters
  {chars: '0123456789', min: 3}, // At least 3 digits
  {chars: '!@#$&*?|%+-_./:;=()[]{}', min: 2}, // At least 2 special chars
]);
export default Landing;
